import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import News from './pages/news';
import Donate from './pages/Donate';
import WhoWeAre from './pages/WhoWeAre';
import Team from './pages/Team';
import Products from './pages/Products';
import Projects from './pages/Projects';
import MembershipForm from './pages/membership-form';
import Equipment from './pages/Equipment';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Gallery from './pages/Gallery';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import Roads from './pages/Roads';
import Support from './pages/Support';
import Maintenance from './pages/Maintenance';
import Response from './pages/Response';
import Construction from './pages/Construction';
import Compliance from './pages/Compliance';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";

function App() {
  return (
    <BrowserRouter>
    {/* <TopSection></TopSection> */}
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about' element={<WhoWeAre></WhoWeAre>}></Route>
             <Route path='donate' element={<Donate></Donate>}></Route>
            <Route path='membership-form' element={<MembershipForm></MembershipForm>}></Route>
            <Route path='team' element={<Team></Team>}></Route>
            <Route path='news' element={<News></News>}></Route>
            <Route path='products' element={<Products></Products>}></Route>
            <Route path='programmes' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='pictorial' element={<Gallery></Gallery>}></Route>
             <Route path='tools' element={<Equipment></Equipment>}></Route>
             
            <Route path='certifications' element={<Certification></Certification>}></Route>
             <Route path='contact' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='power' element={<Power></Power>}></Route>
 <Route path='road-construction' element={<Roads></Roads>}></Route>
 <Route path='projects' element={<Projects></Projects>}></Route>
  <Route path='support' element={<Support></Support>}></Route>
  <Route path='maintenance' element={<Maintenance></Maintenance>}></Route>
<Route path='emergency-response' element={<Response></Response>}></Route>
  <Route path='building-construction' element={<Construction></Construction>}></Route> 
   <Route path='regulatory-compliance' element={<Compliance></Compliance>}></Route>           
             {/* Footer Services Links */}

       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
