
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';

import Product1 from '../components/Product1';
import Product2 from '../components/Product2';
// import Tool3 from '../components/Tool3';
// import Tool4 from '../components/Tool4';
// import Tool5 from '../components/Tool5';
// import Tool6 from '../components/Tool6';
// import Tool7 from '../components/Tool7';
// import Tool8 from '../components/Tool8';
// import Tool9 from '../components/Tool9';
// import Tool10 from '../components/Tool10';
// import Tool11 from '../components/Tool11';
// import Tool12 from '../components/Tool12';
const Team = () => {

                      let [modal1, setModal1] = useState(false);
                      let [modal2, setModal2] = useState(false);
                    //   let [modal3, setModal3] = useState(false);
                    //   let [modal4, setModal4] = useState(false);
                    //   let [modal5, setModal5] = useState(false);
                    //   let [modal6, setModal6] = useState(false);
                    //   let [modal7, setModal7] = useState(false);
                    //   let [modal8, setModal8] = useState(false);
                    //   let [modal9, setModal9] = useState(false);
                    //   let [modal10,setModal10] = useState(false);
                    //   let [modal11,setModal11] = useState(false);
                    //   let [modal12,setModal12] = useState(false);

useEffect(()=>{
document.title = 'Our Products | Mr Bees Africa Limited'
},[]);

let modalOneFunc =()=>{
  setModal1(true);
}

let modalTwoFunc =()=>{
  setModal2(true);
}

// let modalThreeFunc =()=>{
//   setModal3(true);
// }

// let modalFourFunc =()=>{
//   setModal4(true);
// }

// let modalFiveFunc =()=>{
//   setModal5(true);
// }

// let modalSixFunc =()=>{
//   setModal6(true);
// }

// let modalSevenFunc =()=>{
//   setModal7(true);
// }

// let modalEightFunc =()=>{
//   setModal8(true);
// }

// let modalNineFunc =()=>{
//   setModal9(true);
// }

// let modalTenFunc =()=>{
//   setModal10(true);
// }

// let modalElevenFunc =()=>{
//   setModal11(true);
// }

// let modalTwelveFunc =()=>{
//   setModal12(true);
// }

let closeModalOne = ()=>{
setModal1(false);

}

let closeModalTwo = ()=>{
setModal2(false);
}

// let closeModalThree = ()=>{
// setModal3(false);
// }

// let closeModalFour = ()=>{
// setModal4(false);
// }

// let closeModalFive = ()=>{
// setModal5(false);
// }

// let closeModalSix = ()=>{
// setModal6(false);
// }

// let closeModalSeven = ()=>{
// setModal7(false);
// }

// let closeModalEight = ()=>{
// setModal8(false);
// }

// let closeModalNine = ()=>{
// setModal9(false);
// }

// let closeModalTen = ()=>{
// setModal10(false);
// }
// let closeModalEleven = ()=>{
// setModal11(false);
// }

// let closeModalTwelve = ()=>{
// setModal12(false);
// }

let closeAll =(e)=>{
  if(e.target.classList.contains('modal-container')){
  setModal1(false);
  setModal2(false);
//   setModal3(false);
//   setModal4(false);
//   setModal5(false);
//   setModal6(false);
//   setModal7(false);
//   setModal8(false);
//   setModal9(false);
//   setModal10(false);
//   setModal11(false);
//   setModal12(false);
  }

}

window.addEventListener('keydown',(e)=>{
 
  if(e.key === 'Escape'){
    setModal1(false);
    setModal2(false);
    // setModal3(false);
    // setModal4(false);
    // setModal5(false);
    // setModal6(false);
    // setModal7(false);
    // setModal8(false);
    // setModal9(false);
    // setModal10(false);
    // setModal11(false);
    // setModal12(false);
  }

})


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container table-container'>
          <h2 className="home-h2 team-h2">Our Products</h2>
          
{/* Cards Start */}
            <div className='services-container-new'>
          <article className='single-service-new single-service-new1'>
              {/* <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div> */}
              <div className='tools-slides-cover'>
                <Product1></Product1>
              </div>
            <h3 className='skill-h3 service-h3-new'>Mr Bees Propolis Tincture</h3>
            <button    onClick={modalOneFunc} className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new single-service-new1'>
            {/* <div className='service-icon'>
                <FaReact></FaReact>
              </div> */}
              <div className='tools-slides-cover'>
                <Product2></Product2>
              </div>
            <h3 className='skill-h3 service-h3-new'>Mr Bees Africa Bees Wax</h3>
             <button  onClick={modalTwoFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          {/* <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool3></Tool3>
              </div>
            <h3 className='skill-h3 service-h3-new'>Bee Brushes</h3>
             <button onClick={modalThreeFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool4></Tool4>
              </div>
            <h3 className='skill-h3 service-h3-new'>Honey Press Machines</h3>
             <button onClick={modalFourFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool5></Tool5>
              </div>
            <h3 className='skill-h3 service-h3-new'>Honey Extractor Machines</h3>
             <button onClick={modalFiveFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool6></Tool6>
              </div>
            <h3 className='skill-h3 service-h3-new'>Double Sieve Strainers</h3>
             <button onClick={modalSixFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service-new'>
        
              <div className='tools-slides-cover'>
                <Tool7></Tool7>
              </div>
            <h3 className='skill-h3 service-h3-new'>Honey Refractometers</h3>
             <button onClick={modalSevenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
          
              <div className='tools-slides-cover'>
                <Tool8></Tool8>
              </div>
            <h3 className='skill-h3 service-h3-new'>Heavy-Duty Bee Suits</h3>
             <button onClick={modalEightFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                <Tool9></Tool9>
              </div>
            <h3 className='skill-h3 service-h3-new'>Kenya Top Bar Bee Hives</h3>
             <button onClick={modalNineFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
          
              <div className='tools-slides-cover'>
                <Tool10></Tool10>
              </div>
            <h3 className='skill-h3 service-h3-new'>Langstroth Bee Hives</h3>
             <button onClick={modalTenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service-new'>
           
              <div className='tools-slides-cover'>
                <Tool11></Tool11>
              </div>
            <h3 className='skill-h3 service-h3-new'>Bee Venom Collector Machines</h3>
             <button onClick={modalElevenFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          </article>

          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                <Tool12></Tool12>
              </div>
            <h3 className='skill-h3 service-h3-new'>Bee Venom Collector Machines</h3>
             <button onClick={modalTwelveFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          </article> */}
      </div>

      {/* Cards End */}

      {/* Modals Start */}

      <section className={`${modal1?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalOne}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Mr Bees Propolis Tincture</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Propolis is a substance made by Bees from plant exudates. Propolis has strong antiviral, antifungal and antibacterial properties. Mr Bees Propolis tinctures  can be used as a nutritional supplement to boost your immune system against infections by microbes, to clear stomach ulcers, throat infections, common flu, scabies, ring worms to mention but a few. For adults, take 15 drops in hot water, drink while still warm. For wound healing, apply directly to the affected area. Price: 5000 Ug Shs.</p>
          </div>

      </section>

       <section className={`${modal2?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTwo}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Mr Bees Africa Bees Wax</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>From thousands of Ugandan rural Beekeepers, Mr Bees Africa Ltd purchases, processes and Exports quality organic Beeswax to the European Union and other markets.</p>
          </div>
      </section>

      {/* <section className={`${modal3?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalThree}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Bee Brushes</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A Bee Brush is a long wooden handled soft brush with a twin row of natural fibres. Use as an occasional brush for removing any straggler bees from a comb. </p>
          </div>
      </section>

      <section className={`${modal4?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFour}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Honey Press Machines</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A honey press is a machine that squeezes honeycomb between two surfaces.</p>

          </div>
      </section>

      <section className={`${modal5?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFive}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Honey Extractor Machines </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A honey extractor is a mechanical device used in the extraction of honey from honeycombs. A honey extractor extracts the honey from the honey comb without destroying the comb. Extractors work by centrifugal force. A drum or container holds a frame basket which spins, flinging the honey out. With this method the wax comb stays intact within the frame and can be reused by the bees.</p>
    </div>
      </section>

      <section className={`${modal6?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSix}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Double Sieve Strainers</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A double stainless steel Honey Strainer with adjustable arms to fit on top of a most settling tanks. It is made from two separate strainers.</p>
    <p className='modal-p'>Top strainer has a mesh size of 1.5mm and catches larger bits, like lumps of wax and bees!</p>
     <p className='modal-p'>The bottom strainer catches any smaller particles and has a mesh size of 0.5mm.</p>
    </div>
      </section>

      <section className={`${modal7?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSeven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Honey Refractometers</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A honey refractometer is what beekeepers use to measure the volume or concentration of moisture in their honey to ensure optimal extraction. It's an important step in making sure that your honey is ripe enough to be harvested and stored in jars. You have to invest in one if you have any hope of selling your honey.</p>
    
    </div>
      </section>

         <section className={`${modal8?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEight}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Heavy-Duty Bee Suits</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Our protective bee suits are made from long lasting polycotton which offers full protection. We have white, beige and camo ventilated bee suits available.</p>
    
    </div>
      </section>

       <section className={`${modal9?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalNine}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Kenya Top Bar Bee Hive</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A top-bar hive is a single-story frameless beehive in which the comb hangs from removable bars. Yields up to 19kgs of comb honey per harvest per bee hive.</p>
    
    </div>
      </section>

      <section className={`${modal10?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTen}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Langstroth Bee Hives</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>In modern  beekeeping, a Langstroth hive is any vertically modular beehive that has the key features of vertically hung frames, a bottom board with entrance for the bees, boxes containing frames for brood and honey and an inner cover and top cap to provide weather protection. It yields 20kgs per harvest.</p>
    
    </div>
      </section>

      <section className={`${modal11?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEleven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Bee Venom Collector Machines</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>A Bee Venom Collector Machine is a device that gives electro-stimulation to living bees so that bee venom can be collected from them.</p>
    
    </div>
      </section>

      <section className={`${modal12?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTwelve}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Bee Pollen Trappers</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>In a commercially managed honey bee hives, pollen can be easily collected from returning foragers through the use of pollen 'traps'. These traps are placed either in front of or underneath the hive entrance, and returning foragers are required to walk through a pollen trapper.</p>
    
    </div>
      </section> */}

      {/* Modals End */}

         </div>
    </section>
  )
}

export default Team