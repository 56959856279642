import React,{useEffect} from 'react'

const CV = () => {
    useEffect(()=>{
document.title = 'Membership Form | Mr Bees Africa Limited'
},[])
  return (
    <section className='section CV-section'>
        <div className='container CV-container'>

            <iframe src="docs/membership-form.pdf" className='cv-page'></iframe>


        </div>



    </section>
  )
}

export default CV