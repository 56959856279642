import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.706), rgba(0, 0, 0, 0.706)), url("./images/3.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='about' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='team' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Team</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='programmes' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Programmes</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='projects' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Projects</p></Link> 
                        </div>
                        <div className='footer-link'>
                              <Link to='contact' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Write To Us</p></Link> 
                        </div>

                       
                        
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Address</h3>
                      <p>
                   
Kilembe Cell, Nyamityobola Ward, Kakoba Division, Mbarara south, Mbarara City - Uganda <br></br>(Uganda Southwest District Office)
                        </p>
                       

                    </div>
<div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                        +256758 826722 <br></br>+256772 826722
                        <br></br>
                       (Bagoole Fred -  District Superintendent)
                      
                      </p>

                    </div>
                   

                    
                      

             </div>


                   <div className='footer-inner-content'>


                    

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  
                   <p>
                   info@nazarenesouthwestug.org
<br></br>
ugandasouthwest@gmail.com

                    </p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span>
                                 <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>

                                 <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> The Church of the Nazarene - Southwest Uganda | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer