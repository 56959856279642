import Carousel from 'react-bootstrap/Carousel';

function ContactCarousel() {
  return (
    <Carousel indicators={false} className="contact-carousel">
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>


    </Carousel>
  );
}

export default ContactCarousel;