import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FaArrowRight} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | The Church of the Nazarene - Southwest Uganda';

},[]);

  return (
  <>
         <section className="section-cover who-we-are-cover">
             <h2 className="home-h2 wwa-h2">Our Team</h2>
                <div className="team-content">
     
  <article className="team-member">
         
         
    <div className="team-image">
     <img src="images/4.jpg" alt="photo"  className="team-photo"></img>
    </div>
     <div className="team-details">
        <h3 className="team-h3">Headteacher</h3>
        {/* <p className="team-p"></p> */}
        <p class="team-p team-p1"><strong>Tel:</strong>+256750 189281</p>
     </div>

</article>


<article className="team-member">
         
         
    <div className="team-image">
     <img src="images/5.jpg" alt="photo"  className="team-photo"></img>
    </div>
     <div className="team-details">
        <h3 className="team-h3">Stephen Natukunda - Project Manager</h3>
        {/* <p className="team-p"></p> */}
        <p class="team-p team-p1"><strong>Tel:</strong> +256753 289192</p>
     </div>

</article>
<article className="team-member">
         
         
    <div className="team-image">
     <img src="images/6.jpg" alt="photo"  className="team-photo"></img>
    </div>
     <div className="team-details">
        <h3 className="team-h3">Bagoole Fred - Overseer</h3>
        {/* <p className="team-p"></p> */}
        <p class="team-p team-p1"><strong>Tel:</strong> +256758 826722</p>
     </div>

</article>
                 </div>   
         </section>
         <section className='committee-cover'>
             
               <img src='./images/1.jpg' alt='image' className='committee-image'></img>
              <p className='team-p team-p-a'>Nazarene Youth District Committee </p>
             
          </section>
          
      <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='/contact' className='home-cta-link' onClick={scrollFunc}>   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section>
     </>
    
  )
}

export default Team