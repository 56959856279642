import React, {useEffect} from 'react';
import {FaArrowRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}

const Services = () => {

useEffect(()=>{
document.title = 'Our Projects | The Church of the Nazarene - Southwest Uganda';

},[]);


  return (
    <>
    {/* <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Programmes</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Rehabilitation of Children with Disabilities </h3>
                    <p></p>
                     
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    
                    <h3 className='title-h3 service-h3'>Childrens' and Girls’ Life Skills Program

</h3>
                     <p></p>
                     
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Smile with a Child Africa Project</h3>
                       <p></p>
                       

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    
                    <h3 className='title-h3 service-h3'>Voice for children living with HIV/AIDS


</h3>
                     <p></p>
                     
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Girl Child in Education Inclusion</h3>
                       <p></p>
                       

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>                  
                    <h3 className='title-h3 service-h3'>Uplifting economic status of women through IGAs to improve livelihoods</h3>
                     <p></p>                   
                  </article>
                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>                
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Provision of basic amenities to Children</h3>
                       <p></p>
                       

                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>

             <div className='service-container odd-service-container'>
                  <article className='service-title'>                  
                    <h3 className='title-h3 service-h3'>The re-usable pads project</h3>
                     <p></p>                   
                  </article>
                  <article className='service-slides'>
                    <Plumbing></Plumbing>                
                  </article>     
            </div>
   
           </div>

    </section>
    <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='contact' className='home-cta-link' target="_blank" >   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section> */}
       <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url("./images/3.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1 goals-text2'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Projects</h2>
              {/* <p className='core-values-intro'> In order to serve the community better, TCU is guided by the
following core values:</p> */}
              <div className='goals-list'>
                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span> */}
                          <p className='goal-description goal-description-a'>1. Kahooma Parents Primary School.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span> */}
                          <p className='goal-description goal-description-a'>2. Pastors' children sponsorship.</p>
                     </div>

                     <div className='goal'>
                         {/* <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span> */}
                          <p className='goal-description goal-description-a'>3. Goats project.</p>
                     </div>                 
                </div>
           </article>
       </div>

  </section>
  <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='/contact' className='home-cta-link' onClick={scrollFunc}>   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section>
    </>
  )
}

export default Services