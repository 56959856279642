import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {FaArrowRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}

const Home = () => {



useEffect(()=>{
document.title = "The Church of the Nazarene - Southwest Uganda";

},[]);



  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>
<p className='home-p'><strong>The Church of the Nazarene</strong> officially started in 1990 holding our first assembly in Kampala, by then it was based in The Uganda Western Region. The district was then divided into two districts namely:- Uganda Central and Uganda Southwest district respectively in 1996 at Kamaya. Uganda Southwest had the first District Assembly at Mbarara Glory Church on 20<sup>th</sup> November 1997 with Paul Cunningham who was the General Superintendent, Dan Anderson who was the District Superintendent and Bagoole Fred who was the District Secretary. </p>

   <p className='home-p'>In 2000, Bagoole Fred the current District Superintendent was appointed in the position he is in to lead the district with James Mbyemeire as District Secretary and James Diehl as General Superintendent.</p>            

 
        </div>

    </section>
    {/* <section className='section-cover gallery-cover gallery-cover1' >
       <h2 className="home-h2 gallery-h2"></h2>
      <div className='content-container  gallery gallerya'>

         <article className='gallery-content gallery-contenta'>
          <img src='./images/32.jpeg' className='gallery-image' alt='image'></img>
            <UWA></UWA>
           <p>The Executive Director and the Secretary who are the founders of the organization with some of the youths</p>
         </article>
      </div>

    </section> */}
    <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='contact' className='home-cta-link' onClick={scrollFunc}>   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section>
    {/* <section className='review-cover'>
      <h2 className="home-h2 home-h2a">What Our Clients Say</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>
          <div className='all-reviews'>
              <article className='review-box'>
                  <div className='review-content'>
                    <FaQuoteLeft className='left-quote'></FaQuoteLeft>
                <p className='review-text'> I’m so pleased with Mr Bees Africa Propolis that I have been using for many many years. I first discovered this wonderful product in Wandegeya.  I love the fact it is natural as too helping the community’s. I have shared it with my work colleagues also and they all love it too.</p>
                   <RiArrowDownSFill className='down-arrow'></RiArrowDownSFill>
                   </div>
                   <div className='review-footer'>
                    <span></span> <h4>Kakalamu Micheal</h4>
              </div>
              </article>

               <article className='review-box'>
                  <div className='review-content'>
                    <FaQuoteLeft className='left-quote'></FaQuoteLeft>
                <p className='review-text'>I love the beesuit and smoker. Khaki cotton with African fabric trim with large pockets. Two-piece suit is a great idea. I doubt my white suits will ever come out of the cupboard again. Great equipment and happy to support a great cause!</p>
                   <RiArrowDownSFill className='down-arrow'></RiArrowDownSFill>
                   </div>
                   <div className='review-footer'>
                    <span></span> <h4>Annabelle UK</h4>
              </div>
              </article>

              <article className='review-box'>
                  <div className='review-content'>
                    <FaQuoteLeft className='left-quote'></FaQuoteLeft>
                <p className='review-text'>Mr Bees Africa equipment has been of top quality and well suited for the job.  The beesuit, in particular, is comfortable, hard wearing and makes me stand out in the bee yard and the bees love it.</p>
                  <RiArrowDownSFill className='down-arrow'></RiArrowDownSFill>
                   </div>
                   <div className='review-footer'>
                    <span></span> <h4>Semakula Allan</h4>
              </div>
              </article>

             

              <article className='review-box'>
                  <div className='review-content'>
                <FaQuoteLeft className='left-quote'></FaQuoteLeft>
                <p className='review-text'>I'm so happy because I took William kabanaku to my failed apiary. Things turned all round and am happy.</p>
                   <RiArrowDownSFill className='down-arrow'></RiArrowDownSFill>
                   </div>
                   <div className='review-footer'>
                    <span></span> <h4>Gregory Asiimwe</h4>
              </div>
              </article>
              
          </div>

          
    </section> */}
    {/* <section className='section-cover partners-cover'>
       <div className='content-container goals-container '>
           <article className='goals-text goals-texta'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Partners</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>World Vision</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>World Bank</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Uganda Wildlife Authority</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Source of the Nile Beekeepers Consortium Ltd (SONIBECO LTD)</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Hoima International Airport</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Mercy Corps Ltd</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>New Singapore Apiaries</p>
                     </div>

                     <div className='goal goal1'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Vision Group</p>
                     </div>

                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

  </section> */}
  
   
    </>
  )
}

export default Home