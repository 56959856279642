import React, {useEffect} from 'react';
import {FaArrowRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = 'Donate | Triangular Communities Uganda';

},[]);


  return (
    <>
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Donate</h2>
           <div className='content-container services-container news-center'>
             <p className='donations-p'>
For donations, write to us at <strong>info@triangularcommunities.org </strong> or call us on <strong>+256776 648932/+25670 3019331.</strong>

             </p>
           </div>

    </section>
    <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='contact' className='home-cta-link' target="_blank" >   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section>
    </>
  )
}

export default Services