import React, {useEffect} from 'react';
import UWA from '../components/UWA';
import Hoima from '../components/Hoima';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Pictorial | Mr Bees Africa Limited';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Pictorial</h2>
      <div className='content-container gallery'>
       <article className='gallery-content'>
          <img src='./images/2.jpg' className='gallery-image' alt='image'></img>
           <p>Our Honey </p>
         </article>

          <article className='gallery-content  '>
          {/* <img src='./images/2.jpg' className='gallery-image' alt='image'></img> */}
            <UWA></UWA>
           <p>Photos at At Uganda Wildlife Authority</p>
         </article>

         <article className='gallery-content  '>
          <img src='./images/19.jpg' className='gallery-image' alt='image'></img>
            {/* <UWA></UWA> */}
           <p>Training beekeepers under World Vision</p>
         </article>

          <article className='gallery-content  '>
          {/* <img src='./images/2.jpg' className='gallery-image' alt='image'></img> */}
            <Hoima></Hoima>
           <p>Share "yourself bee" project Hoima by World Bank</p>
         </article>

      </div>

    </section>
  )
}

export default Gallery