import Carousel from 'react-bootstrap/Carousel';

function Tool2() {
  return (
    <Carousel indicators={false} className='services-slides tools-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100 "
          src="./images/57.jpg"
          alt="First slide"
        />

      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/58.jpg"
          alt="First slide"
        />
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/59.jpg"
          alt="First slide"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default Tool2;