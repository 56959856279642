import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import {FaCheckCircle,FaArrowRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});
}

const WhoWeAre = () => {


useEffect(()=>{
document.title = 'About Us | The Church of the Nazarene - Southwest Uganda';

},[]);


  return (
    <>
  
     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Who We Are</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To plant holiness reproducing churches where disciples are making disciples in every people group.
</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p >To make Christlike disciples in the Western Region of Uganda.
</p>
                           
                            {/* <h3 className='title-h3'>Our Objectives</h3>
                          <div className='vm-line'></div>
                          <ul>
                            <li> </li>
                            <li></li>
                            <li></li>
                          </ul> */}



                    </article>

                    


                 </div>

          </div>
    </section>

    

     <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.706), rgba(0, 0, 0, 0.706)), url("./images/1.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container goals-container1'>
           <article className='goals-text goals-text1 goals-text2'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Core Values</h2>
              {/* <p className='core-values-intro'> In order to serve the community better, TCU is guided by the
following core values:</p> */}
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>A Christian people</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>A holiness people</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'>A missional people</p>
                     </div>

                    


                </div>
                   

           </article>

           




       </div>

  </section>
   <section className='cta-cover'>
       <h3 className='title-h3 service-h3 home-cta-h3'>To get involved or volunteer, write to us!</h3>
  <Link to='/contact' className='home-cta-link' onClick={scrollFunc}>   <button  className='service-btn home-cta'>Write To Us <span>
              <FaArrowRight></FaArrowRight>
              </span></button></Link> 
    </section>
    

    </>
  )
}

export default WhoWeAre