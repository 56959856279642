import Carousel from 'react-bootstrap/Carousel';

function Tool1() {
  return (
    <Carousel indicators={false} className='services-slides tools-slides product-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100 "
          src="./images/9.jpg"
          alt="First slide"
        />

      </Carousel.Item>

    </Carousel>
  );
}

export default Tool1;